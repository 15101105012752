import React from "react"
import Layout from "../components/layout"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import Gallery from "../components/gallery"
import { graphql } from "gatsby"
import SEO from "../components/seo"

const Lokacija = ({ data }) => {
  const intl = useIntl()
  const slike = data.allFile.edges
  return (
    <Layout>
      <SEO lang={intl.locale} title={intl.formatMessage({ id: "lokacija" })} />
      <div className="section blue-background lokacija">
        <div className="content">
          <div className="card">
            <h1>{intl.formatMessage({ id: "lokacija" })}</h1>
            <p>
              <FormattedMessage
                id="lokacija.tekst"
                values={{
                  p: (...chunks) => <p>{chunks}</p>,
                  br: <br />,
                }}
              />
            </p>


            <div
              style={{
                width: "100%",
                margin: "3rem 0",
              }}
            >
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11268.09713294193!2d13.6465911!3d45.0853007!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477cbf62243daf75%3A0xeae0364e635bbf1e!2sApartments%20and%20Rooms%20Irena!5e0!3m2!1sen!2shr!4v1680374007860!5m2!1sen!2shr" width="100%" height="400" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

            </div>

            <h2>Rovinj</h2>
            <p style={{ marginBottom: "1.5rem" }}>
              <FormattedMessage
                id="pocetna.lokacija"
                values={{
                  p: (...chunks) => <p>{chunks}</p>,
                  br: <br />,
                }}
              />
            </p>

            <Gallery slike={slike} />


          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Lokacija

export const query = graphql`
  query {
    allFile(
      filter: { relativeDirectory: { regex: "/lokacija/" } }
      sort: { fields: relativeDirectory, order: DESC }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(sizes: "(min-width: 10px) 2000px, 2000px", maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
          id
          relativeDirectory
        }
      }
    }
  }
`
